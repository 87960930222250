import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BarContainer from "./components/BarContainer";
import Configuraciones from "./components/Configuraciones";
import MesaDetail from "./components/MesaDetail";
import Caja from "./components/Caja";
import CodigosQR from "./components/CodigosQR";
import Login from "./components/Login";
import LoginBar from "./components/LoginBar";
import MenuGenerator from "./components/MenuGenerator";
import Menu from "./components/Menu";
import MenuMesa from "./components/MenuMesa"
import MenuForm from "./components/MenuForm";
import LoginPin from "./components/LoginPin";
import AboutUs from "./components/AboutUs";
import ChangePassword from "./components/ChangePassword";

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<BarContainer />} />
          <Route path="/login" element={<Login />} />
          <Route path="/:nombreDelBar" element={<Caja />} />
          <Route path="/:nombreDelBar/login" element={<LoginBar />} />
          <Route path="/:nombreDelBar/configuraciones" element={<Configuraciones />} />
          <Route path="/:nombreDelBar/codigos" element={<CodigosQR />} />
          <Route path="/:nombreDelBar/menuGenerator" element={<MenuForm />} />
          <Route path="/:nombreDelBar/changePassword" element={<ChangePassword />} />
          <Route path="/:nombreDelBar/menu" element={<Menu />} />
          <Route path="/:nombreDelBar/loginPin/:numeroDeMesa" element={<LoginPin />} />
          <Route path="/:nombreDelBar/menu/:numeroDeMesa" element={<MenuMesa />} />
          <Route path="/:nombreDelBar/mesa/:numeroDeMesa" element={<MesaDetail />} />
          <Route path="/:nombreDelBar/:numeroDeMesa/aboutUs" element={<AboutUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
