import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase/Firebase.js";
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import useTitle from "../hooks/useTitle.js";
import MenuStyles from "../styles/Menu.module.css";
import estilos from "../styles/menuGenration.module.css";
import Swal from "sweetalert2";

const ChangePassword = () => {
    const { nombreDelBar } = useParams();
    const navigate = useNavigate();
    useTitle(`Cambiar contraseña: ${nombreDelBar}`);
    const [barData, setBarData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newPassword, setNewPassword] = useState("");

    useEffect(() => {
        const adminLogged = localStorage.getItem("adminLogged");
        if (!adminLogged) {
            navigate("/login");
        }

        const unsubscribe = onSnapshot(collection(db, "bares"), (querySnapshot) => {
            const baresData = querySnapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    direccion: doc.data().direccion,
                    dueño: doc.data().dueño,
                    nombre: doc.data().nombre,
                    username: doc.data().username,
                    password: doc.data().password,
                    telefono: doc.data().telefono,
                };
            });

            const bar = baresData.find((bar) => bar.nombre === nombreDelBar);
            setBarData(bar);

            setLoading(false);
        });

        return () => unsubscribe();
    }, [nombreDelBar, navigate]);

    const handlePasswordChange = async (event) => {
        event.preventDefault();
        if (barData) {
            try {
                const barRef = doc(db, "bares", barData.id);
                await updateDoc(barRef, {
                    password: newPassword,
                });
                Swal.fire({
                    icon: "success",
                    title: "¡Éxito!",
                    text: "Contraseña actualizada con exito!",
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "rgb(221, 25, 25)",
                  });
                setNewPassword("");
            } catch (error) {
                console.error("Error al actualizar la contraseña:", error);
                Swal.fire({
                    icon: "error",
                    title: "Opps!",
                    text: `${error}`,
                    showConfirmButton: true,
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "rgb(221, 25, 25)",
                  });
            }
        }
    };

    const handleGoBack = () => {
            navigate(`/${nombreDelBar}/configuraciones`);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div>Cargando...</div>
            </div>
        );
    }

    return (
        <>
            <button className={estilos.botonAtras} onClick={handleGoBack}>
                Atras
            </button>
            <div className={MenuStyles.containerPrincipal}>
                <div className="text-center">
                    <h2 className={`${MenuStyles.title}`}>Cambiar Contraseña</h2>
                    {barData && (
                        <>
                            <h4>Contraseña actual: {barData.password}</h4>
                            <form onSubmit={handlePasswordChange}>
                                <div>
                                    <input
                                        className={estilos.input}
                                        type="text"
                                        id="newPassword"
                                        placeholder="Nueva contraseña"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <br />
                                <button type="submit" className={estilos.botonAgrcategoria}>Cambiar Contraseña</button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
