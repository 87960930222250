import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/Firebase.js";
import QrLogo from "../imagenes/qr.png";
import MenuBar from "../imagenes/menu-restaurant.png";
import estilos from "../styles/Configuraciones.module.css";
import changePasswordImg from '../imagenes/change-password.png';

const Configuraciones = () => {
  const navigate = useNavigate();

  const { nombreDelBar } = useParams();
  const [mesas, setMesas] = useState([]);

  useEffect(() => {
    const obtenerMesasDelBar = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const barQuerySnapshot = await getDocs(barQuery);

        if (!barQuerySnapshot.empty) {
          const barId = barQuerySnapshot.docs[0].id;

          const mesasQuery = query(collection(db, "bares", barId, "mesas"));
          const mesasQuerySnapshot = await getDocs(mesasQuery);

          const mesasData = mesasQuerySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMesas(mesasData);
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener las mesas del bar:", error);
      }
    };

    obtenerMesasDelBar();
  }, [nombreDelBar]);

  const handleGoBack = () => {
    navigate(`/${nombreDelBar}`);
  };

  return (
    <div className={`container ${estilos.container}`}>
      <div className="text-center mb-5">
        <h2 className={`lead display-3 ${estilos.titulo}`}>{nombreDelBar}</h2>
        <h2 className={`lead display-6 mb-4 ${estilos.subtitulo}`}>
          Configuraciones
        </h2>
      </div>
      <div
        className={`row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 ${estilos.containerlinks}`}
      >
        <Link
          to={`/${nombreDelBar}/codigos`}
          className={`text-center btn btn-light ${estilos.iconos}`}
        >
          <img src={QrLogo} alt="Codigo QR Logo" style={{ height: "50px" }} />
          <br />
          Generar QRs
        </Link>

        <Link
          to={`/${nombreDelBar}/menuGenerator`}
          className={`text-center btn btn-light ${estilos.iconos}`}
        >
          <img
            src={MenuBar}
            alt="Generador de Menú"
            style={{ height: "50px" }}
          />
          <br />
          Generador de Menús
        </Link>

        <Link
          to={`/${nombreDelBar}/changePassword`}
          className={`text-center btn btn-light ${estilos.iconos}`}
        >
          <img
            src={changePasswordImg}
            alt="Cambiar contraseña"
            style={{ height: "50px" }}
          />
          <br />
          Cambiar contraseña
        </Link>
      </div>
      <div className={estilos.botonAtras}>
        <button className={estilos.boton} onClick={handleGoBack}>
          Atras
        </button>
      </div>
      <footer className={estilos.footer}>
        <p className={estilos.copyright}>
          &copy; {new Date().getFullYear()} MozoCall
        </p>
      </footer>
    </div>
  );
};

export default Configuraciones;
