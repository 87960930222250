import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  writeBatch,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/Firebase.js";
import { Link, useNavigate } from "react-router-dom";
import Estilos from "../styles/BarContainer.module.css";
import imagen from "../imagenes/necesito2.webp";
import necesito from "../styles/QueNecesito.module.css";
import Swal from 'sweetalert2';

const BarContainer = () => {
  const [bares, setBares] = useState([]);
  const [formData, setFormData] = useState({
    nombre: "",
    direccion: "",
    dueño: "",
    cantidadMesas: 0,
    telefono: "",
  });
  const [editData, setEditData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const adminLogged = localStorage.getItem("adminLogged");
    if (!adminLogged) {
      navigate("/login");
    }

    const unsubscribe = onSnapshot(collection(db, "bares"), (querySnapshot) => {
      const baresData = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          direccion: doc.data().direccion,
          dueño: doc.data().dueño,
          nombre: doc.data().nombre,
          username: doc.data().username,
          password: doc.data().password,
          telefono: doc.data().telefono,
        };
      });
      setBares(baresData);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditInputChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const username = generateUsername(formData.nombre);
      const password = generateRandomPassword(5);

      const pin = generateRandomPIN(4);

      const telefono = formData.telefono;

      const formDataWithAuth = {
        ...formData,
        username: username,
        password: password,
        pin: pin,
        telefono: telefono,
      };

      const docRef = await addDoc(collection(db, "bares"), formDataWithAuth);

      const mesasBatch = writeBatch(db);
      for (let i = 0; i < formData.cantidadMesas; i++) {
        const mesaData = {
          numero: i + 1,
          estado: "Disponible",
          mozo: false,
          cuenta: false,
          msg: [],
        };

        const mesasCollectionRef = collection(db, "bares", docRef.id, "mesas");

        const newMesaRef = await addDoc(mesasCollectionRef, mesaData);
      }

      setFormData({
        nombre: "",
        direccion: "",
        dueño: "",
        cantidadMesas: 0,
        telefono: "",
      });
    } catch (error) {
      console.error("Error al agregar nuevo bar:", error);
    }
  };

  const handleEdit = (bar) => {
    setEditData(bar);
    setIsEditing(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const barRef = doc(db, "bares", editData.id);
      await updateDoc(barRef, editData);
      setIsEditing(false);
      Swal.fire({
        title: "Actualizado!",
        text: "El bar ha sido actualizado.",
        icon: "success",
        confirmButtonColor: "#da0707",
      });
    } catch (error) {
      console.error("Error al actualizar el bar:", error);
    }
  };

  const handleDelete = async (barId, barName) => {
    try {
      Swal.fire({
        title: `Estas seguro de eliminar a ${barName}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteDoc(doc(db, "bares", barId));
          Swal.fire({
            title: "Borrado!",
            text: "El bar ha sido eliminado.",
            icon: "success",
            confirmButtonColor: "#da0707",
          });
        }
      });
    } catch (error) {
      console.error("Error al eliminar el bar:", error);
      alert("Error al eliminar el bar", error);
    }
  };

  function generateRandomPassword(length) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }

  const generateUsername = (nombre) => {
    const username = nombre.toLowerCase().replace(/\s/g, "");
    return username;
  };

  function generateRandomPIN(length) {
    const charset = "0123456789";
    let pin = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      pin += charset[randomIndex];
    }
    return pin;
  }

  return (
    <>
      <div className="container">
        <div className={Estilos.container1}>
          <div className={Estilos.containerimagen}>
            <img className={Estilos.imagen} src={imagen} alt="" />
          </div>

          <h1 className={`lead display-3 mb-4 ${Estilos.titulo}`}>
            MozoCall
          </h1>
          <form onSubmit={handleSubmit} className={Estilos.containerform}>
            <h2 className={`lead display-6 ${Estilos.subtitulo}`}>
              Agregar nuevo bar
            </h2>
            <div className={Estilos.containerlabel}>
              <div className={Estilos.contlabel1}>
                <label className={Estilos.label}>
                  Nombre del Bar:
                  <input
                    className={Estilos.input}
                    type="text"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={Estilos.label}>
                  Dirección:
                  <input
                    className={Estilos.input}
                    type="text"
                    name="direccion"
                    value={formData.direccion}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
              <div className={Estilos.contlabel2}>
                <label className={Estilos.label}>
                  Dueño:
                  <input
                    className={Estilos.input}
                    type="text"
                    name="dueño"
                    value={formData.dueño}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={Estilos.label}>
                  Nº de Mesas:
                  <input
                    className={Estilos.input}
                    type="number"
                    name="cantidadMesas"
                    value={formData.cantidadMesas}
                    onChange={handleInputChange}
                  />
                </label>
                <label className={Estilos.label}>
                  Telefono
                  <input
                    className={Estilos.input}
                    type="number"
                    name="telefono"
                    value={formData.telefono}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
            </div>
            <div className={Estilos.containerboton}>
              <button className={`btn btn-primary ${Estilos.boton}`} type="submit">
                Agregar Nuevo Bar
              </button>
            </div>
          </form>
        </div>
        <h2 className={`lead display-6 mt-5 ${Estilos.titulo}`}>Todos los bares</h2>
        <div className="bar-grid">
          {bares.map((bar) => (
            <div key={bar.id} className="mb-3">
              <Link
                to={`/${bar.nombre}`}
                style={{ textDecoration: "none" }}
              >
                <div className={`card mb-3 container ${Estilos.containerCard}`}>
                  <h3 className="mt-2 mb-3">{bar.nombre}</h3>
                  <p>Dueño: {bar.dueño}</p>
                  <p>Dirección: {bar.direccion}</p>
                  <p>Nombre de usuario: <b>{bar.username}</b></p>
                  <p>Contraseña: <b>{bar.password}</b></p>
                  <p>Telefono: <b>{bar.telefono}</b></p>
                </div>
              </Link>
              <div className="d-flex" style={{ marginBottom: "30px" }}>
                <button
                  className={`${Estilos.boton}`}
                  onClick={() => handleEdit(bar)}
                  style={{ marginRight: "15px", backgroundColor: "gray", border: "gray", marginTop: "5px" }}
                >
                  Editar <i className="bi bi-pencil"></i>
                </button>
                <button
                  className={Estilos.boton}
                  onClick={() => handleDelete(bar.id, bar.nombre)}
                  style={{ marginTop: "5px" }}
                >
                  Eliminar <i className="bi bi-trash3"></i>
                </button>
              </div>
            </div>
          ))}
        </div>

        {isEditing && (
          <div className="modal fade show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content text-center">
                <div className="modal-header">
                  <h5 className={`modal-title ${Estilos.titulo}`}>Editar datos del bar:</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setIsEditing(false)}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleEditSubmit}>
                    <label className={Estilos.label} style={{ marginBottom: "15px" }}>
                      Nombre del Bar:
                      <input
                        className={Estilos.input}
                        type="text"
                        name="nombre"
                        value={editData.nombre}
                        onChange={handleEditInputChange}
                      />
                    </label>
                    <label className={Estilos.label} style={{ marginBottom: "15px" }}>
                      Dirección:
                      <input
                        className={Estilos.input}
                        type="text"
                        name="direccion"
                        value={editData.direccion}
                        onChange={handleEditInputChange}
                      />
                    </label>
                    <label className={Estilos.label} style={{ marginBottom: "15px" }}>
                      Dueño:
                      <input
                        className={Estilos.input}
                        type="text"
                        name="dueño"
                        value={editData.dueño}
                        onChange={handleEditInputChange}
                      />
                    </label>
                    <label className={Estilos.label} style={{ marginBottom: "15px" }}>
                      Telefono:
                      <input
                        className={Estilos.input}
                        type="number"
                        name="telefono"
                        value={editData.telefono}
                        onChange={handleEditInputChange}
                      />
                    </label>
                    <div className="modal-footer">
                      <button type="submit" className={Estilos.boton}>
                        Guardar cambios <i className="bi bi-floppy2"></i>
                      </button>
                      <button
                        type="button"
                        className={Estilos.boton}
                        onClick={() => setIsEditing(false)}
                      >
                        Cerrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer className={`${necesito.footer}`}>
        <p className={necesito.copyright}>
          &copy; {new Date().getFullYear()} MozoCall - Todos los derechos reservados.
          <br />
          {" "}
        </p>
      </footer>
    </>
  );
};

export default BarContainer;
