
// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { db } from "../firebase/Firebase.js";
// import {
//   collection,
//   onSnapshot,
//   query,
//   where,
//   getDocs,
// } from "firebase/firestore";
// import useTitle from "../hooks/useTitle.js";
// import MenuStyles from "../styles/Menu.module.css";
// import imagen from "../imagenes/necesito2.webp";
// import MesaDetailStyles from "../styles/MesaDetail.module.css";

// const MenuMesa = () => {
//   const { nombreDelBar, numeroDeMesa } = useParams();
//   const navigate = useNavigate();
//   useTitle(`${nombreDelBar}: Menú`);
//   const [menu, setMenu] = useState([]);
//   const [barId, setBarId] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [isSearchVisible, setIsSearchVisible] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");

//   useEffect(() => {
//     const fetchBarId = async () => {
//       try {
//         const barQuery = query(
//           collection(db, "bares"),
//           where("nombre", "==", nombreDelBar)
//         );
//         const unsubscribe = onSnapshot(barQuery, (snapshot) => {
//           snapshot.forEach((doc) => {
//             setBarId(doc.id);
//           });
//         });
//         return () => unsubscribe();
//       } catch (error) {
//         console.error("Error al obtener el ID del bar:", error);
//       }
//     };

//     fetchBarId();
//   }, [nombreDelBar]);

//   useEffect(() => {
//     if (barId) {
//       const fetchCategories = async () => {
//         try {
//           const menuCollection = collection(db, `bares/${barId}/menu`);
//           const unsubscribe = onSnapshot(menuCollection, (snapshot) => {
//             const categories = snapshot.docs.map((doc) => ({
//               id: doc.id,
//               ...doc.data(),
//               productos: [],
//             }));
//             setMenu(categories);
//             setLoading(false);
//           });
//           return () => unsubscribe();
//         } catch (error) {
//           console.error("Error al obtener las categorías del menú:", error);
//         }
//       };

//       fetchCategories();
//     }
//   }, [barId]);

//   useEffect(() => {
//     if (barId) {
//       const fetchProducts = async () => {
//         try {
//           const productUnsubscribers = menu.map(async (categoria) => {
//             const productsCollection = collection(
//               db,
//               `bares/${barId}/menu/${categoria.id}/productos`
//             );
//             const unsubscribe = onSnapshot(productsCollection, (snapshot) => {
//               const productos = snapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 ...doc.data(),
//               }));
//               setMenu((prevMenu) =>
//                 prevMenu.map((cat) =>
//                   cat.id === categoria.id
//                     ? { ...cat, productos: productos }
//                     : cat
//                 )
//               );
//             });
//             return unsubscribe;
//           });
          
//           return () => {
//             productUnsubscribers.forEach((unsubscribe) => unsubscribe());
//           };
//         } catch (error) {
//           console.error("Error al obtener los productos del menú:", error);
//         }
//       };

//       fetchProducts();
//     }
//   }, [barId, menu]);

//   const handleGoBack = () => {
//     navigate(`/${nombreDelBar}/mesa/${numeroDeMesa}`);
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const filteredCategories = menu
//     .map((categoria) => ({
//       ...categoria,
//       productos: categoria.productos.filter((producto) =>
//         producto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         producto.description.toLowerCase().includes(searchTerm.toLowerCase())
//       ),
//     }))
//     .filter(categoria => categoria.productos.length > 0);

//   const toggleSearch = () => {
//     setIsSearchVisible(!isSearchVisible);
//   };

//   if (loading) {
//     return (
//       <div
//         className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
//       >
//         <div>
//           <img
//             className={MesaDetailStyles.imagencargando}
//             src={imagen}
//             alt=""
//           />
//         </div>
//         <div className="text-center">
//           <h4 className={MenuStyles.tituloloader}>Cargando...</h4>
//           <div className={`${MenuStyles.loader} text-center`}></div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <>
//       <div className={MenuStyles.containerPrincipal}>
//         <nav className={`${MenuStyles.navbar} navbar navbar-expand-lg`}>
//           <div>
//             <button className={MenuStyles.botonatras} onClick={handleGoBack}>
//               {" "}
//               <i className="bi bi-arrow-left-circle-fill"></i>
//             </button>
//           </div>
//           <p className={MenuStyles.nombreBar} href="#">
//             {nombreDelBar}
//           </p>
//           <button className={MenuStyles.searchButton} onClick={toggleSearch}>
//             <i className="bi bi-search"></i>
//           </button>
//           {isSearchVisible && (
//             <div>
//               <input
//                 type="text"
//                 className={`form-control ${MenuStyles.searchInput}`}
//                 placeholder="Buscar categoría..."
//                 value={searchTerm}
//                 onChange={handleSearchChange}
//               />
//             </div>
//           )}
//         </nav>
//       </div>
//       <div className={MenuStyles.container}>
//         <div className="text-center">
//           <h2 className={`${MenuStyles.title} mb-4`}>Menú</h2>
//         </div>
//         <div className="row">
//           <div className="col-md-9">
//             {filteredCategories.map((categoria) => (
//               <div key={categoria.id} className="mb-3" id={categoria.categoria}>
//                 <h2 className={MenuStyles.categoria}>{categoria.name}</h2>
//                 <hr />
//                 {categoria.productos.map((producto, index) => (
//                   <div
//                     key={index}
//                     className={`${MenuStyles.menuContain}  mb-3`}
//                   >
//                     <div className="col-sm-6">
//                       <label className={MenuStyles.nombre}>
//                       {producto.name.charAt(0).toUpperCase() + producto.name.slice(1)}
//                         {/* {producto.name} */}
//                       </label>{" "}
//                       <br />
//                       <small>
//                         <i>{producto.description}</i>
//                       </small>
//                     </div>
//                     <div className={MenuStyles.precio}>
//                       <p>
//                         <small>$</small>
//                         {producto.price}
//                       </p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MenuMesa;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase/Firebase.js";
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import useTitle from "../hooks/useTitle.js";
import MenuStyles from "../styles/Menu.module.css";
import imagen from "../imagenes/necesito2.webp";
import MesaDetailStyles from "../styles/MesaDetail.module.css";

const MenuMesa = () => {
  const { nombreDelBar, numeroDeMesa } = useParams();
  const navigate = useNavigate();
  useTitle(`${nombreDelBar}: Menú`);
  const [menu, setMenu] = useState([]);
  const [barId, setBarId] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch Bar ID
  useEffect(() => {
    const fetchBarId = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const unsubscribe = onSnapshot(barQuery, (snapshot) => {
          snapshot.forEach((doc) => {
            setBarId(doc.id);
          });
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener el ID del bar:", error);
      }
    };

    fetchBarId();
  }, [nombreDelBar]);

  // Fetch Categories
  useEffect(() => {
    if (barId) {
      const fetchCategories = async () => {
        try {
          const menuCollection = collection(db, `bares/${barId}/menu`);
          const unsubscribe = onSnapshot(menuCollection, (snapshot) => {
            const categories = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              productos: [],
            }));
            setMenu(categories);
            setLoading(false);
          });
          return () => unsubscribe();
        } catch (error) {
          console.error("Error al obtener las categorías del menú:", error);
        }
      };

      fetchCategories();
    }
  }, [barId]);

  // Fetch Products
  useEffect(() => {
    if (barId && menu.length > 0) {
      const fetchProducts = async () => {
        try {
          const productUnsubscribers = menu.map((categoria) => {
            const productsCollection = collection(
              db,
              `bares/${barId}/menu/${categoria.id}/productos`
            );
            const unsubscribe = onSnapshot(productsCollection, (snapshot) => {
              const productos = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setMenu((prevMenu) =>
                prevMenu.map((cat) =>
                  cat.id === categoria.id
                    ? { ...cat, productos: productos }
                    : cat
                )
              );
            });
            return unsubscribe;
          });
          
          return () => {
            productUnsubscribers.forEach((unsubscribe) => unsubscribe());
          };
        } catch (error) {
          console.error("Error al obtener los productos del menú:", error);
        }
      };

      fetchProducts();
    }
  }, [barId, menu.map((cat) => cat.id).join(",")]);

  const handleGoBack = () => {
    navigate(`/${nombreDelBar}/mesa/${numeroDeMesa}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCategories = menu
    .map((categoria) => ({
      ...categoria,
      productos: categoria.productos.filter((producto) =>
        producto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        producto.description.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter(categoria => categoria.productos.length > 0);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
      >
        <div>
          <img
            className={MesaDetailStyles.imagencargando}
            src={imagen}
            alt=""
          />
        </div>
        <div className="text-center">
          <h4 className={MenuStyles.tituloloader}>Cargando...</h4>
          <div className={`${MenuStyles.loader} text-center`}></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={MenuStyles.containerPrincipal}>
        <nav className={`${MenuStyles.navbar} navbar navbar-expand-lg`}>
          <div>
            <button className={MenuStyles.botonatras} onClick={handleGoBack}>
              {" "}
              <i className="bi bi-arrow-left-circle-fill"></i>
            </button>
          </div>
          <p className={MenuStyles.nombreBar} href="#">
            {nombreDelBar}
          </p>
          <button className={MenuStyles.searchButton} onClick={toggleSearch}>
            <i className="bi bi-search"></i>
          </button>
          {isSearchVisible && (
            <div>
              <input
                type="text"
                className={`form-control ${MenuStyles.searchInput}`}
                placeholder="Buscar categoría..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          )}
        </nav>
      </div>
      <div className={MenuStyles.container}>
        <div className="text-center">
          <h2 className={`${MenuStyles.title} mb-4`}>Menú</h2>
        </div>
        <div className="row">
          <div className="col-md-9">
            {filteredCategories.map((categoria) => (
              <div key={categoria.id} className="mb-3" id={categoria.categoria}>
                <h2 className={MenuStyles.categoria}>{categoria.name}</h2>
                <hr />
                {categoria.productos.map((producto, index) => (
                  <div
                    key={index}
                    className={`${MenuStyles.menuContain}  mb-3`}
                  >
                    <div className="col-sm-6">
                      <label className={MenuStyles.nombre}>
                      {producto.name.charAt(0).toUpperCase() + producto.name.slice(1)}
                      </label>{" "}
                      <br />
                      <small>
                        <i>{producto.description}</i>
                      </small>
                    </div>
                    <div className={MenuStyles.precio}>
                      <p>
                        <small>$</small>
                        {producto.price}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuMesa;
