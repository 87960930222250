import React from "react";
import { Link, useParams } from "react-router-dom";
import footer from "../styles/Footer.module.css";

function Footer() {
  const { nombreDelBar } = useParams();

  return (
    <footer className="footer fixed-bottom bg-light">
      <div className={`container text-center ${footer.container}`}>
        <Link to={`/${nombreDelBar}/configuraciones`} className="btn">
          <div className={footer.containerIcono}>
            <i className={`bi-tools ${footer.icono}`}></i>
            <div className={footer.configuraciones}>Configuraciones</div>
          </div>
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
