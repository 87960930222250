import React, { useState, useEffect } from "react";

import imagen from "../imagenes/necesito2.webp";

import { useNavigate, Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  onSnapshot
} from "firebase/firestore";
import { db } from "../firebase/Firebase.js";
import necesito from "../styles/QueNecesito.module.css";
import MesaDetailStyles from "../styles/MesaDetail.module.css";
import Swal from "sweetalert2";
import { useSesion } from "../contextoGlobal/SesionContext.js";

function MesaDetail() {
  const navigate = useNavigate();
  const [seleccion, setSeleccion] = useState({
    mozo: false,
    cuenta: false,
    mensaje: "",
    msg: [],
  });
  const { nombreDelBar, numeroDeMesa } = useParams();
  const [mesaId, setMesaId] = useState(null);
  const [barId, setBarId] = useState(null);
  const [enviando, setEnviando] = useState(false);
  const [apagado, setApagado] = useState(false);
  const {sesionActiva, setSesionActiva} = useSesion();

  useEffect(() => {
    const verificarSesion = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const tiempoExpiracion = localStorage.getItem("tokenExpiracion");
        if (tiempoExpiracion && Date.now() < parseInt(tiempoExpiracion)) {
          setSesionActiva(true);
        } else {
          navigate(`/${nombreDelBar}/loginPin/${numeroDeMesa}`);
        }
      } else {
        navigate(`/${nombreDelBar}/loginPin/${numeroDeMesa}`);
      }
    };
  
    verificarSesion();
  
    // Verificar la sesión cada cierto intervalo de tiempo (por ejemplo, cada minuto)
    const intervalo = setInterval(verificarSesion, 60000);
  
    // Limpiar el intervalo cuando el componente se desmonta para evitar fugas de memoria
    return () => clearInterval(intervalo);
  }, [navigate, nombreDelBar, numeroDeMesa]);

  useEffect(() => {
    const obtenerMesaId = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const barQuerySnapshot = await getDocs(barQuery);

        if (!barQuerySnapshot.empty) {
          const barId = barQuerySnapshot.docs[0].id;
          setBarId(barId);

          const barDocRef = doc(db, "bares", barId);
          const unsubscribe = onSnapshot(barDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const barData = docSnapshot.data();
              setApagado(barData.apagado);
            }
          });

          const mesaQuery = query(
            collection(db, "bares", barId, "mesas"),
            where("numero", "==", parseInt(numeroDeMesa))
          );
          const mesaQuerySnapshot = await getDocs(mesaQuery);

          if (!mesaQuerySnapshot.empty) {
            const mesaId = mesaQuerySnapshot.docs[0].id;
            setMesaId(mesaId);
            console.log(mesaId);
          } else {
            console.log(
              "No se encontró ninguna mesa con el número especificado."
            );
          }

          return () => unsubscribe();
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener la mesa:", error);
      }
    };

    obtenerMesaId();
  }, [nombreDelBar, numeroDeMesa]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setSeleccion({
      ...seleccion,
      [name]: name === "mensaje" ? value : checked,
    });
  };

  const handleSubmit = async () => {
    if (
      !seleccion.mozo &&
      !seleccion.cuenta &&
      seleccion.mensaje.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Debe seleccionar una opción o escribir un mensaje!",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "rgb(221, 25, 25)",
      });
      return;
    }
    setEnviando(true);
    try {
      const barQuery = query(
        collection(db, "bares"),
        where("nombre", "==", nombreDelBar)
      );
      const barQuerySnapshot = await getDocs(barQuery);

      if (!barQuerySnapshot.empty) {
        const barId = barQuerySnapshot.docs[0].id;

        const mesaRef = doc(db, "bares", barId, "mesas", mesaId);
        console.log(mesaRef);
        const mesaSnapshot = await getDoc(mesaRef);
        let currentMsg = mesaSnapshot.data().msg || [];

        currentMsg.push(seleccion.mensaje);

        if (mesaSnapshot.exists()) {
          if (seleccion.mensaje === "") {
            const mesaData = {
              cuenta: seleccion.cuenta || mesaSnapshot.data().cuenta,
              estado: "Ocupada",
              mozo: seleccion.mozo || mesaSnapshot.data().mozo,
            };
            await updateDoc(mesaRef, mesaData);
            Swal.fire({
              icon: "success",
              title: "Mensaje enviado correctamente",
              showConfirmButton: true,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "rgb(221, 25, 25)",
            });

            // Restablecer el estado inicial después del envío exitoso
            setSeleccion({
              mozo: false,
              cuenta: false,
              mensaje: "",
              msg: [],
            });
          } else {
            const mesaData = {
              cuenta: seleccion.cuenta || mesaSnapshot.data().cuenta,
              estado: "Ocupada",
              mozo: seleccion.mozo || mesaSnapshot.data().mozo,
              msg: currentMsg,
            };

            await updateDoc(mesaRef, mesaData);
            Swal.fire({
              icon: "success",
              title: "Mensaje enviado correctamente",
              showConfirmButton: true,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "rgb(221, 25, 25)",
            });

            // Restablecer el estado inicial después del envío exitoso
            setSeleccion({
              mozo: false,
              cuenta: false,
              mensaje: "",
              msg: [],
            });
          }
        } else {
          console.error("El documento de la mesa no existe.");
        }
      } else {
        console.log("No se encontró ningún bar con el nombre especificado.");
      }
    } catch (error) {
      console.error("Error al actualizar la mesa:", error);
    } finally {
      // Restablecer el estado de envío al finalizar, ya sea éxito o error
      setEnviando(false);
    }
  };

  const info = () =>{
    navigate(`/${nombreDelBar}/${numeroDeMesa}/aboutUs`);
  }

  if (!mesaId) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
      >
        <div>
          <img
            className={MesaDetailStyles.imagencargando}
            src={imagen}
            alt=""
          />
        </div>
        <div className="text-center">
          <h4 className={necesito.titulo}>Cargando...</h4>
          <div className={`${MesaDetailStyles.loader} text-center`}></div>
        </div>
      </div>
    );
  }

  return (
    <div className={`container ${necesito.container}`}
    style={{ pointerEvents: apagado ? "none" : "auto", opacity: apagado ? 0.5 : 1 }}
    >
      <div>
        <img className={necesito.imagen} src={imagen} alt="" />
        <div>
          <p className={necesito.mozolink}>MozoCall</p>
        </div>
      </div>
      <h1 className={necesito.titulo}>¿Qué necesitas?</h1>
      <div className={`form-container ${necesito.formContainer}`}>
        <form>
        <div className={necesito.containermenu}>
              <Link
                className={necesito.botonmenu}
                to={`/${nombreDelBar}/menu/${numeroDeMesa}`}
                style={{ textDecoration: "none" }}
              >
                Menú
              </Link>
            </div>
          <div className="form-check ">
            <input
              className={`form-check-input ${necesito.checkbox}`}
              type="checkbox"
              id="mozoCheckbox"
              name="mozo"
              checked={seleccion.mozo}
              onChange={handleInputChange}
            />
            <label
              className={`form-check-label ${necesito.labels}`}
              htmlFor="mozoCheckbox"
            >
              Necesito al mozo
            </label>
          </div>
          <div className="form-check ">
            <input
              className={`form-check-input  ${necesito.checkbox}`}
              type="checkbox"
              id="cuentaCheckbox"
              name="cuenta"
              checked={seleccion.cuenta}
              onChange={handleInputChange}
            />
            <label
              className={`form-check-label ${necesito.labels}`}
              htmlFor="cuentaCheckbox"
            >
              Necesito la cuenta
            </label>
           
          </div>
          <div className={`form-group mt-5 ${necesito.tituloadicional}`}>
            <label htmlFor="mensajeInput" className={necesito.mensajeAdicional}>
              Envia tu mensaje
            </label>
            <textarea
              placeholder=" Necesito..."
              type="text"
              className={`form-control ${necesito.inputmensaje}`}
              id="mensajeInput"
              name="mensaje"
              value={seleccion.mensaje}
              onChange={handleInputChange}
            />
          </div>
          <div className={`${necesito.containerboton}`}>
            <button
              className={`btn mt-3 ${necesito.botonenviar}`}
              type="button"
              onClick={handleSubmit}
              disabled={enviando}
            >
              {enviando ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </form>
      </div>
      <footer className={necesito.footer}>
        <p className={necesito.copyright}>
          &copy; {new Date().getFullYear()} MozoCall
        </p>
        <button onClick={info} className={necesito.botoninfo}>Info <i class={`bi bi-info-circle ${necesito.icono}`}></i></button>
      </footer>
    </div>
  );
}

export default MesaDetail;
