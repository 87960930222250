import React, { createContext, useState, useContext } from 'react';

const SesionContext = createContext();

export const useSesion = () => useContext(SesionContext);

export const SesionProvider = ({ children }) => {
  const [sesionActiva, setSesionActiva] = useState(false);

  return (
    <SesionContext.Provider value={{ sesionActiva, setSesionActiva }}>
      {children}
    </SesionContext.Provider>
  );
};
