import React, { useState } from "react";
import { db } from "../firebase/Firebase";
import LoginStyle from "../styles/Login.module.css";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import imagen from "../imagenes/necesito2.webp";


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const adminQuery = query(
        collection(db, "admin"),
        where("email", "==", email)
      );
      const adminDocs = await getDocs(adminQuery);
      if (adminDocs.empty) {
        setError("No se encontró un usuario con ese email");
        return;
      }

      const adminData = adminDocs.docs[0].data();

      if (adminData.password === password) {
        localStorage.setItem("adminLogged", email);
        navigate("/");
      } else {
        setError("Contraseña incorrecta");
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setError("Error al iniciar sesión, por favor inténtalo de nuevo");
    }
  };

  return (
    <div className={LoginStyle.bodyContainer}>
      <div className={LoginStyle.containerimagen}>
        <img className={LoginStyle.imagen} src={imagen} alt="" />
        <div>
          <p className={LoginStyle.mozolink}>MozoCall</p>
        </div>
      </div>
      <div className={LoginStyle.background}></div>
      <div className={LoginStyle.card}>
        <h2 className={LoginStyle.titulo}>
          <strong>Iniciar Sesión</strong> 
          Administrador
        </h2>
        {error && <p className={LoginStyle.error}>{error}</p>}
        <form className={LoginStyle.form} onSubmit={handleLogin}>
          <input
            className={LoginStyle.input}
            type="email"
            placeholder="Ingrese su email."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className={LoginStyle.input}
            type="password"
            placeholder="Ingrese su contraseña."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className={LoginStyle.boton} type="submit">Entrar</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
