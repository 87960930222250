import React, { useState, useEffect } from "react";
import {
  getDocs,
  query,
  collection,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/Firebase.js";
import { useSesion } from "../contextoGlobal/SesionContext.js";
import { useParams, useNavigate } from "react-router-dom";
import necesito from "../styles/QueNecesito.module.css";
import imagen from "../imagenes/necesito2.webp";
import LoginStyle from "../styles/Login.module.css";

const LoginPin = () => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [barPin, setBarPin] = useState("");
  const { setSesionActiva } = useSesion();
  const { nombreDelBar, numeroDeMesa } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const obtenerPinBar = async () => {
      try {
        const barQuerySnapshot = await getDocs(
          query(collection(db, "bares"), where("nombre", "==", nombreDelBar))
        );
        if (!barQuerySnapshot.empty) {
          const barDoc = barQuerySnapshot.docs[0];
          const barData = barDoc.data();
          const barPin = barData.pin;
          setBarPin(barPin);
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener el pin del bar:", error);
      }
    };

    obtenerPinBar();

    // Establecer la escucha del pin del bar
    const barRef = collection(db, "bares");
    const unsubscribe = onSnapshot(barRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          const modifiedBarData = change.doc.data();
          setBarPin(modifiedBarData.pin);
        }
      });
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (pin === barPin) {
      // Si el PIN es correcto, establecer el token y la expiración
      const token = generarToken();
      const tiempoExpiracion = Date.now() + 4 * 60 * 60 * 1000; // Token expira en 2 horas
      // const tiempoExpiracion = Date.now() + 1 * 60 * 1000; // token 1 minuto para pruebas
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiracion", tiempoExpiracion.toString());

      // Establecer sesión activa en true
      setSesionActiva(true);
      navigate(`/${nombreDelBar}/mesa/${numeroDeMesa}`);
    } else {
      setError("PIN incorrecto");
    }
  };

  const handleInputChange = (e) => {
    setPin(e.target.value);
    setError("");
  };

  // Función para generar un token aleatorio (simulación)
  const generarToken = () => {
    return Math.random().toString(36).substr(2); // Token aleatorio
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <div className={LoginStyle.bodyContainer2}>
        <div className={LoginStyle.containerimagen}>
          <img className={LoginStyle.imagen} src={imagen} alt="" />
          <div>
            <p className={LoginStyle.mozolink}>MozoCall</p>
          </div>
        </div>

        <h2 className={`mt-3 ${necesito.iniciarsesion}`}>Iniciar sesión</h2>
        <h3 className={necesito.iniciarsesion}>{nombreDelBar}</h3>
        <form onSubmit={handleSubmit}>
          <div className="mt-3">
            <input
              className={necesito.ingresepin}
              type="text"
              id="pinInput"
              value={pin}
              onChange={handleInputChange}
              placeholder="Ingrese el PIN"
              autoComplete="off"
            />
          </div>
          {error && <div className="text-danger mt-2">{error}</div>}
          <div className="text-center">
            <button type="submit" className="btn btn-danger mt-3">
              Ingresar
            </button>
          </div>
        </form>
        <footer className={`${necesito.footer} mt-5`}>
          <p className={necesito.copyRight}>
            &copy; {new Date().getFullYear()} MozoCall - Mesa {numeroDeMesa}
          </p>
        </footer>
      </div>
    </div>
  );
};

export default LoginPin;
