import React from 'react';
import { createRoot } from 'react-dom/client'; 
import './index.css';
import App from './App';
import DataContext from './contextoGlobal/DataContext';
import { SesionProvider } from './contextoGlobal/SesionContext';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DataContext.Provider value={{ selectedOptions: [], setSelectedOptions: () => {} }}>
      <SesionProvider>
              <App />
      </SesionProvider>
    </DataContext.Provider>
  </React.StrictMode>
);
