// EditCategoryForm.js
import React, { useState } from 'react';
import estilos from "../styles/menuGenration.module.css";


const EditCategoryForm = ({ category, onSave, onCancel }) => {
  const [editedName, setEditedName] = useState(category.category);

  const handleSave = () => {
    onSave(editedName);
  };

  return (
    <div  className={estilos.containerPrincipal3}>
      {/* <h3 className={estilos.categoria3}>Editar Categoría</h3> */}
      <label className={estilos.label2}>Nombre de la Categoría:</label>
      <input
       className={estilos.input2}
        type="text"
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
        required
      />
      <div className={estilos.container5}>
      <button className={estilos.guardarModal} onClick={handleSave}>Guardar</button>
      <button className={estilos.eliminarModal} onClick={onCancel}>Cancelar</button>
      </div>
    </div>
  );
};

export default EditCategoryForm;
