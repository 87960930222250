import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase/Firebase.js";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import useTitle from "../hooks/useTitle.js";
import MenuStyles from "../styles/Menu.module.css";
import imagen from "../imagenes/necesito2.webp";
import MesaDetailStyles from "../styles/MesaDetail.module.css";

const Menu = () => {
  const { nombreDelBar } = useParams();
  const navigate = useNavigate();
  useTitle(`${nombreDelBar}: Menú`);
  const [menu, setMenu] = useState([]);
  const [barId, setBarId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({});

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchBarId = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const unsubscribe = onSnapshot(barQuery, (snapshot) => {
          snapshot.forEach((doc) => {
            setBarId(doc.id);
          });
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener el ID del bar:", error);
      }
    };

    fetchBarId();
  }, [nombreDelBar]);

  useEffect(() => {
    if (barId) {
      const fetchCategories = async () => {
        try {
          const menuCollection = collection(db, `bares/${barId}/menu`);
          const unsubscribe = onSnapshot(menuCollection, (snapshot) => {
            const categories = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setMenu(categories);
            setLoading(false);
          });
          return () => unsubscribe();
        } catch (error) {
          console.error("Error al obtener las categorías del menú:", error);
        }
      };

      fetchCategories();
    }
  }, [barId]);

  useEffect(() => {
    if (barId && menu.length > 0) {
      const fetchProducts = async () => {
        try {
          const productUnsubscribers = menu.map((categoria) => {
            const productsCollection = collection(
              db,
              `bares/${barId}/menu/${categoria.id}/productos`
            );
            const unsubscribe = onSnapshot(productsCollection, (snapshot) => {
              const productos = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setProducts((prevProducts) => ({
                ...prevProducts,
                [categoria.id]: productos,
              }));
            });
            return unsubscribe;
          });
          
          return () => {
            productUnsubscribers.forEach((unsubscribe) => unsubscribe());
          };
        } catch (error) {
          console.error("Error al obtener los productos del menú:", error);
        }
      };

      fetchProducts();
    }
  }, [barId, menu]);

  const handleGoBack = () => {
    navigate(`/${nombreDelBar}/configuraciones`);
  };

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
      >
        <div>
          <img
            className={MesaDetailStyles.imagencargando}
            src={imagen}
            alt=""
          />
        </div>
        <div className="text-center">
          <h4 className={MenuStyles.tituloloader}>Cargando...</h4>
          <div className={`${MenuStyles.loader} text-center`}></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={MenuStyles.containerPrincipal}>
        <nav className={`${MenuStyles.navbar} navbar navbar-expand-lg`}>
          <div>
            <button className={MenuStyles.botonatras} onClick={handleGoBack}>
              {" "}
              <i className="bi bi-arrow-left-circle-fill"></i>
            </button>
          </div>
          <p className={MenuStyles.nombreBar} href="#">
            {nombreDelBar}
          </p>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleSidebar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
            <ul className={`navbar-nav ms-auto ${MenuStyles.categorias2}`}>
              {menu.map((categoria) => (
                <li key={categoria.id} className="nav-item">
                  <a
                    className={`nav-link ${MenuStyles.subcategorie}`}
                    href={`#${categoria.categoria}`}
                  >
                    {categoria.categoria}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
      <div className={MenuStyles.container}>
        <div className="text-center">
          <h2 className={`${MenuStyles.title}`}>Menú</h2>
        </div>
        <div className="row">
          <div className="col-md-9">
            {menu.map((categoria) => (
              <div key={categoria.id} className="mb-3">
                <h2 className={MenuStyles.categoria}>{categoria.name}</h2>
                <hr />
                {(products[categoria.id] || []).map((producto, index) => (
                  <div
                    key={index}
                    className={`${MenuStyles.menuContain} mb-3`}
                    id={categoria.categoria}
                  >
                    <div className="col-sm-6">
                      <label className={MenuStyles.nombre}>
                        {producto.name}
                      </label>{" "}
                      <br />
                      <small>
                        <i>{producto.description}</i>
                      </small>
                    </div>
                    <div className={MenuStyles.precio}>
                      <p>
                        <small>$</small>
                        {producto.price}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
