import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAtG-c8ydXyPtjHYPIV8N9hoQ4OILn49gw",
    authDomain: "necesito-7310f.firebaseapp.com",
    projectId: "necesito-7310f",
    storageBucket: "necesito-7310f.appspot.com",
    messagingSenderId: "856308202335",
    appId: "1:856308202335:web:16dd7b693e4e3db98e94ea",
    measurementId: "G-DF2CY5B7D4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    db
}
