// EditProductForm.js
import React, { useState } from "react";
import estilos from "../styles/menuGenration.module.css";

const EditProductForm = ({ product, onSave, onCancel }) => {
  const [editedName, setEditedName] = useState(product.name);
  const [editedDescription, setEditedDescription] = useState(
    product.description
  );
  const [editedPrice, setEditedPrice] = useState(product.price);

  const handleSave = () => {
    onSave(editedName, editedDescription, editedPrice);
  };

  return (
    <div className={estilos.containerPrincipal2}>
      {/* <h3 className={estilos.categoria3}>Editar Producto</h3> */}
      <div className={estilos.container6}>
      <label className={estilos.label2}>Nombre del Producto:</label>
      <input
        className={estilos.input2}
        type="text"
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
        required
      />
      <div className={estilos.container7}>
      <label className={estilos.label2}>Descripción del Producto:</label>
      <textarea
        className={estilos.input2}
        type="text"
        value={editedDescription}
        onChange={(e) => setEditedDescription(e.target.value)}
        required
      />
      </div>
      <label className={estilos.label2}>Precio del Producto:</label>
      <input
        className={estilos.input2}
        type="number"
        value={editedPrice}
        onChange={(e) => setEditedPrice(e.target.value)}
        required
      />
      </div>
      <div className={estilos.container5}>
      <button className={estilos.guardarModal} onClick={handleSave}>Guardar</button>
      <button className={estilos.eliminarModal} onClick={onCancel}>Cancelar</button>
      </div>
    </div>
  );
};

export default EditProductForm;
