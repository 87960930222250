import React, { useState } from "react";
import estilos from "../styles/menuGenration.module.css";
import Swal from "sweetalert2";

const AddProductForm = ({ onSave, onCancel }) => {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");

  const handleSave = () => {
    if (!productName || !productPrice) {
      Swal.fire({
        icon: "warning",
        title: "Por favor complete todos los campos. (Descripción opcional)",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "rgb(221, 25, 25)",
      });
      return;
    }

    onSave(productName, productDescription, productPrice);

    setProductName("");
    setProductDescription("");
    setProductPrice("");
  };

  return (
    <div className={estilos.containerPrincipal2}>
      {/* <h3 className={estilos.categoria3}>Agregar Nuevo Producto</h3> */}
      <div className={estilos.container6}>
        <label className={estilos.label2}>Nombre del Producto:</label>
        <input
          className={estilos.input2}
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          required
        />

        <label className={estilos.label2}>Descripción del Producto:</label>
        <textarea
          className={estilos.input2}
          type="text"
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          required
        />

        <label className={estilos.label2}>Precio del Producto:</label>
        <input
          className={estilos.input2}
          type="number"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          required
        />
      </div>
      <div className={estilos.container5}>
        <button
          className={estilos.guardarModal}
          type="button"
          onClick={handleSave}
        >
          Guardar
        </button>
        <button
          className={estilos.eliminarModal}
          type="button"
          onClick={onCancel}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default AddProductForm;
