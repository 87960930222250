import React, { useState, useEffect } from "react";
import estilos from "../styles/menuGenration.module.css";
import imagen from "../imagenes/necesito2.webp";
import MesaDetailStyles from "../styles/MesaDetail.module.css";
import Swal from "sweetalert2";
import LoginStyle from "../styles/Login.module.css";

import {
  collection,
  addDoc,
  doc,
  onSnapshot,
  where,
  query,
  writeBatch,
  setDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/Firebase";
import { useParams, useNavigate, Link } from "react-router-dom";
import EditCategoryForm from "./EditCategoryForm";
import EditProductForm from "./EditProductForm";
import AddProductForm from "./AddProductForm";

const MenuForm = () => {
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [products, setProducts] = useState([
    { name: "", price: "", description: "" },
  ]);
  const [menu, setMenu] = useState([]);
  const { nombreDelBar } = useParams();
  const [barId, setBarId] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [showAddProductForm, setShowAddProductForm] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changer, setChanger] = useState(0);

  useEffect(() => {
    const fetchBarId = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const unsubscribe = onSnapshot(barQuery, (snapshot) => {
          snapshot.forEach((doc) => {
            setBarId(doc.id);
          });
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener el ID del bar:", error);
      }
    };

    fetchBarId();
  }, [nombreDelBar]);

  useEffect(() => {
    if (barId) {
      const fetchMenu = async () => {
        try {
          const menuCollection = collection(db, `bares/${barId}/menu`);
          const unsubscribe = onSnapshot(
            menuCollection,
            async (menuSnapshot) => {
              const categoryPromises = menuSnapshot.docs.map(
                async (categoryDoc) => {
                  const categoryData = categoryDoc.data();
                  const categoryId = categoryDoc.id;
                  const productsCollection = collection(
                    db,
                    `bares/${barId}/menu/${categoryId}/productos`
                  );
                  const productsSnapshot = await getDocs(productsCollection);
                  const productsData = productsSnapshot.docs.map((doc) => {
                    const productData = doc.data();
                    const productId = doc.id;
                    return {
                      id: productId,
                      categoryId: categoryId,
                      ...productData,
                    };
                  });

                  return {
                    id: categoryId,
                    category: categoryData.name,
                    products: productsData,
                  };
                }
              );

              const resolvedCategories = await Promise.all(categoryPromises);
              setMenu(resolvedCategories);
              setLoading(false);
            }
          );
          return () => unsubscribe();
        } catch (error) {
          console.error("Error al obtener el menú:", error);
        }
      };

      fetchMenu();
    }
  }, [barId, changer]);

  const handleCategoryChange = (e) => {
    setCategoryName(e.target.value);
  };
  // const handleCategoryChange = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[a-zA-Z\s]*$/; // Expresión regular para letras y espacios
  
  //   if (regex.test(value)) {
  //     setCategoryName(value);
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "Solo se permiten letras en el nombre de la categoría.",
  //       showConfirmButton: true,
  //       confirmButtonText: "Aceptar",
  //       confirmButtonColor: "rgb(221, 25, 25)",
  //     });
  //   }
  // };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = products.map((product, i) =>
      i === index ? { ...product, [name]: value } : product
    );
    setProducts(updatedProducts);
  };

  const addProductField = () => {
    setProducts([...products, { name: "", price: "", description: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const categoryRef = await addDoc(collection(db, `bares/${barId}/menu`), {
        name: categoryName,
      });

      const batch = writeBatch(db);
      const categoryId = categoryRef.id;
      const categoryDocRef = doc(db, `bares/${barId}/menu`, categoryId);

      const newProducts = products.map((product) => {
        const productRef = doc(collection(categoryDocRef, "productos"));
        batch.set(productRef, {
          name: product.name,
          price: product.price,
          description: product.description,
        });
        return {
          id: productRef.id,
          categoryId: categoryId,
          name: product.name,
          price: product.price,
          description: product.description,
        };
      });

      await batch.commit();

      const newCategory = {
        id: categoryId,
        category: categoryName,
        products: newProducts,
      };

      setMenu((prevMenu) => [...prevMenu, newCategory]);
      setChanger(changer + 1);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Categoría y productos agregados correctamente.",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "rgb(221, 25, 25)",
      });
      setCategoryName("");
      setProducts([{ name: "", price: "", description: "" }]);
    } catch (error) {
      console.error("Error adding document: ", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un error al agregar la categoría y los productos. Por favor, inténtalo de nuevo.",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "rgb(221, 25, 25)",
      });
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    toggleModal("editCategoryModal", true);
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    toggleModal("editProductModal", true);
  };

  const handleCancelEdit = () => {
    setEditingCategory(null);
    setEditingProduct(null);
    closeModals();
  };

  const handleAddNewProduct = (category) => {
    setShowAddProductForm(true);
    setSelectedCategory(category);
    toggleModal("addProductModal", true);
  };

  const handleDeleteCategory = async (category) => {
    try {
      const productsRef = collection(
        db,
        `bares/${barId}/menu/${category.id}/productos`
      );
      const productSnapshot = await getDocs(productsRef);
      const batch = writeBatch(db);
      productSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      const categoryRef = doc(db, `bares/${barId}/menu`, category.id);
      await deleteDoc(categoryRef);

      setMenu((prevMenu) => prevMenu.filter((item) => item.id !== category.id));
    } catch (error) {
      console.error("Error al eliminar la categoría:", error);
    }
  };

  const handleDeleteProduct = async (product) => {
    try {
      const productRef = doc(
        db,
        `bares/${barId}/menu/${product.categoryId}/productos`,
        product.id
      );
      await deleteDoc(productRef);

      setMenu((prevMenu) => {
        return prevMenu.map((category) => {
          if (category.id === product.categoryId) {
            return {
              ...category,
              products: category.products.filter(
                (item) => item.id !== product.id
              ),
            };
          }
          return category;
        });
      });
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };
  const handleDeleteCategoryWithConfirmation = (category) => {
    Swal.fire({
      title: "¿Estás seguro que deseas borrar esta categoria?",
      // text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCategory(category);
        Swal.fire({
          title: "Borrado!",
          text: "La categoría ha sido borrada.",
          icon: "success",
          confirmButtonColor: "#da0707",
        });
      }
    });
  };

  const handleDeleteProductWithConfirmation = (product) => {
    Swal.fire({
      title: "¿Estás seguro que deseas borrar este producto?",
      // text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteProduct(product);
        Swal.fire({
          title: "Borrado!",
          text: "El producto ha sido borrado.",
          icon: "success",
          confirmButtonColor: "#da0707",
        });
      }
    });
  };
  const handleGoBack = () => {
    navigate(`/${nombreDelBar}/configuraciones`);
  };

  const toggleModal = (modalId, show) => {
    const modal = document.getElementById(modalId);
    if (modal) {
      if (show) {
        modal.classList.add("show");
        modal.style.display = "block";
        modal.setAttribute("aria-modal", "true");
        modal.setAttribute("role", "dialog");
      } else {
        modal.classList.remove("show");
        modal.style.display = "none";
        modal.removeAttribute("aria-modal");
        modal.removeAttribute("role");
      }
    }
  };

  const closeModals = () => {
    toggleModal("editCategoryModal", false);
    toggleModal("editProductModal", false);
    toggleModal("addProductModal", false);
  };

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
      >
        <div>
          <img
            className={MesaDetailStyles.imagencargando}
            src={imagen}
            alt=""
          />
        </div>
        <div className={`text-center ${MesaDetailStyles.cargando}`}>
          <h4 className={MesaDetailStyles.titulo1}>Cargando...</h4>
          <div className={`${MesaDetailStyles.loader} text-center`}></div>
        </div>
      </div>
    );
  }

  return (
    <div className={estilos.containerPrincipal}>
      <form onSubmit={handleSubmit}>
      <div>
        <button className={estilos.botonAtras} onClick={handleGoBack}>
          Atras
        </button>
      </div>
      <div className={LoginStyle.containerimagen}>
        <img className={LoginStyle.imagen} src={imagen} alt="" />
        <div>
          <p className={LoginStyle.mozolink}>MozoCall</p>
        </div>
      </div>

        <h2 className={estilos.titulo}>Agregar Categoría y Productos</h2>
        <h4 className={estilos.categoria}>Categoria</h4>
        <div className={estilos.categoriaConteiner}>
          <label className={estilos.label3}>Nombre de la Categoría</label>
          <input
            className={estilos.input}
            type="text"
            value={categoryName}
            onChange={handleCategoryChange}
            required
          />
        </div>
        <div className={estilos.container2}>
          <h4 className={estilos.subtitulo}>Productos</h4>
          {products.map((product, index) => (
            <div key={index} className={estilos.containerform}>
              <div className={estilos.container1}>
                <div className={estilos.containerform}>
                  <div className={estilos.connombre}>
                    <label className={estilos.label3}>Nombre</label>
                    <input
                      className={estilos.input}
                      type="text"
                      name="name"
                      value={product.name}
                      onChange={(e) => handleProductChange(index, e)}
                      required
                    />
                  </div>
                  <div className={estilos.containerdescirpcion}>
                    <label className={estilos.label3}>Descripción</label>
                    <textarea
                      className={estilos.input}
                      type="text"
                      name="description"
                      value={product.description}
                      onChange={(e) => handleProductChange(index, e)}
                      required
                    />
                  </div>
                </div>
                <div className={estilos.preciocont}>
                  <label className={estilos.label3}>Precio</label>
                  <input
                    className={estilos.input}
                    type="number"
                    name="price"
                    value={product.price}
                    onChange={(e) => handleProductChange(index, e)}
                    required
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={estilos.containerBotones}>
          <button
            className={estilos.agregarprod}
            type="button"
            onClick={addProductField}
          >
            Agregar otro producto
          </button>
          <button className={estilos.agregarprod} type="submit">
            Crear Categoría
          </button>
          <div className={estilos.contbotonmenu}>
            <Link
              className={estilos.btnvermenu}
              to={`/${nombreDelBar}/menu`}
              style={{ textDecoration: "none" }}
            >
              Ver Menú
            </Link>
          </div>
        </div>
      </form>

      <h3 className={estilos.titulo2}>Previsualización del Menú</h3>
      {/* Previsualización del menú */}
      <div className={estilos.container7}>
        {menu.map((category, index) => (
          <div key={index}>
            <div className={estilos.container3}>
              <h4 className={estilos.nombreCategoria}>{category.category}</h4>
              <ul className={estilos.container8}>
                {category.products.map((product, productIndex) => (
                  <li key={productIndex}>
                    <div className={estilos.containerProductos}>
                      <div className={estilos.categoria2}>{product.name}</div>
                      <br />
                      <div className={estilos.label}>
                        Descripción: {product.description}
                      </div>
                      <br />
                      <div className={estilos.label}>
                        Precio: ${product.price}
                      </div>
                      <div className={estilos.containerBotones2}>
                        <button
                          className={estilos.agregarprod2}
                          onClick={() => handleEditProduct(product)}
                        >
                          Editar producto
                        </button>
                        <button
                          className={estilos.eliminarprd}
                          onClick={() =>
                            handleDeleteProductWithConfirmation(product)
                          }
                        >
                          Eliminar producto
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={estilos.container4}>
              <button
                className={estilos.botonAgrcategoria}
                onClick={() => handleEditCategory(category)}
              >
                Editar categoria
              </button>

              <button
                className={estilos.botonAgrcategoria}
                type="button"
                onClick={() => handleAddNewProduct(category)}
              >
                Agregar nuevo producto
              </button>
              <button
                className={estilos.eliminarcategoria}
                onClick={() => handleDeleteCategoryWithConfirmation(category)}
              >
                Eliminar categoría
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* MODAL */}

      <div id="editCategoryModal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className={estilos.containerCerrar}>
              <button
                type="button"
                className={`close ${estilos.cerrarMOdal}`}
                onClick={() => toggleModal("editCategoryModal", false)}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className={`modal-header ${estilos.modalHeader}`}>
              <h3 className={estilos.categoria3}>Editar Categoría</h3>
            </div>

            <div className="modal-body">
              {editingCategory && (
                <EditCategoryForm
                  category={editingCategory}
                  onSave={async (editedName) => {
                    try {
                      const categoryRef = doc(
                        db,
                        `bares/${barId}/menu/${editingCategory.id}`
                      );
                      await setDoc(
                        categoryRef,
                        { name: editedName },
                        { merge: true }
                      );
                      setMenu((prevMenu) => {
                        return prevMenu.map((category) => {
                          if (category.id === editingCategory.id) {
                            return { ...category, category: editedName };
                          }
                          return category;
                        });
                      });
                    } catch (error) {
                      console.error("Error al editar la categoría:", error);
                    }
                    setEditingCategory(null);
                    toggleModal("editCategoryModal", false);
                  }}
                  onCancel={handleCancelEdit}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="editProductModal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className={estilos.containerCerrar}>
              <button
                type="button"
                className={`close ${estilos.cerrarMOdal}`}
                onClick={() => toggleModal("editProductModal", false)}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className={`modal-header ${estilos.modalHeader}`}>
              <h3 className={estilos.categoria3}>Editar Producto</h3>
            </div>
            <div className="modal-body">
              {editingProduct && (
                <EditProductForm
                  product={editingProduct}
                  onSave={async (
                    editedName,
                    editedDescription,
                    editedPrice
                  ) => {
                    try {
                      const productRef = doc(
                        db,
                        `bares/${barId}/menu/${editingProduct.categoryId}/productos/${editingProduct.id}`
                      );
                      await setDoc(
                        productRef,
                        {
                          name: editedName,
                          description: editedDescription,
                          price: editedPrice,
                        },
                        { merge: true }
                      );
                      setMenu((prevMenu) => {
                        return prevMenu.map((category) => {
                          if (category.id === editingProduct.categoryId) {
                            const updatedProducts = category.products.map(
                              (product) => {
                                if (product.id === editingProduct.id) {
                                  return {
                                    ...product,
                                    name: editedName,
                                    description: editedDescription,
                                    price: editedPrice,
                                  };
                                }
                                return product;
                              }
                            );
                            return { ...category, products: updatedProducts };
                          }
                          return category;
                        });
                      });
                    } catch (error) {
                      console.error("Error al editar el producto:", error);
                    }
                    setEditingProduct(null);
                    toggleModal("editProductModal", false);
                  }}
                  onCancel={handleCancelEdit}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="addProductModal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className={estilos.containerCerrar}>
              <button
                type="button"
                className={`close ${estilos.cerrarMOdal}`}
                onClick={() => toggleModal("addProductModal", false)}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className={`modal-header ${estilos.modalHeader}`}>
              <h3 className={estilos.categoria3}>Agregar Producto</h3>
            </div>

            <div className="modal-body">
              {showAddProductForm && (
                <AddProductForm
                  onSave={async (name, description, price) => {
                    try {
                      const newProduct = {
                        name: name,
                        description: description,
                        price: price,
                      };
                      const categoryId = selectedCategory.id;
                      const productRef = await addDoc(
                        collection(
                          db,
                          `bares/${barId}/menu/${categoryId}/productos`
                        ),
                        newProduct
                      );
                      setShowAddProductForm(false);
                      setChanger(changer + 1);
                      setSelectedCategory(null);
                      toggleModal("addProductModal", false);
                    } catch (error) {
                      console.error(
                        "Error al guardar el nuevo producto:",
                        error
                      );
                    }
                  }}
                  onCancel={handleCancelEdit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuForm;
