import React, { useState, useEffect, useRef } from "react";
import pedido from "../styles/Pedidos.module.css";
import imagen from "../imagenes/necesito2.webp";
import Footer from "./Footer";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/Firebase";
import { useParams, useNavigate } from "react-router-dom";
import MesaDetailStyles from "../styles/MesaDetail.module.css";
import Swal from "sweetalert2";
import alertaSonido from "../components/sonidos/achive-sound-132273.mp3";

function Caja() {
  const [mesas, setMesas] = useState([]);
  const { nombreDelBar } = useParams();
  const [barId, setBarId] = useState(null);
  const [mesasDisponibles, setMesasDisponibles] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [barPin, setBarPin] = useState(null);
  const [apagado, setApagado] = useState(true);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const navigate = useNavigate();
  const audioRef = useRef(null);

  const [mesasPrev, setMesasPrev] = useState([]);

  useEffect(() => {
    const obtenerMesas = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const barQuerySnapshot = await getDocs(barQuery);

        if (!barQuerySnapshot.empty) {
          const barId = barQuerySnapshot.docs[0].id;
          const mesasQuery = collection(db, "bares", barId, "mesas");
          const unsubscribe = onSnapshot(mesasQuery, (snapshot) => {
            const nuevasMesas = [];
            snapshot.forEach((doc) => {
              nuevasMesas.push({ id: doc.id, ...doc.data() });
            });

            setMesas(nuevasMesas);

            // Calculando mesas disponibles
            const mesasDisponiblesCount = nuevasMesas.filter(
              (mesa) => mesa.estado === "Disponible"
            ).length;
            setMesasDisponibles(mesasDisponiblesCount);

            setLoading(false);
          });

          return unsubscribe;
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener las mesas:", error);
      }
    };

    obtenerMesas();
  }, [nombreDelBar]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, []);

  useEffect(() => {
    if (mesasPrev.length > 0) {
      mesas.forEach((mesa) => {
        const mesaAnterior = mesasPrev.find((m) => m.id === mesa.id);
        if (mesaAnterior) {
          if (
            (mesa.cuenta && !mesaAnterior.cuenta) ||
            (mesa.mozo && !mesaAnterior.mozo) ||
            mesa.msg.length > mesaAnterior.msg.length
          ) {
            if (soundEnabled && audioRef.current) {
              audioRef.current.play();
            } else {
              console.log("Error: audioRef.current es nulo o está desactivado");
            }
            document.title = "¡Nuevo mensaje!";
            new Notification("¡Nuevo mensaje en MozoCall!");
          }
        }
      });
    }
    setMesasPrev(mesas);
  }, [mesas]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        document.title = "Que Necesito?";
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const obtenerIdDelBar = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const barQuerySnapshot = await getDocs(barQuery);

        if (!barQuerySnapshot.empty) {
          const barId = barQuerySnapshot.docs[0].id;
          setBarId(barId);
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener el ID del bar:", error);
      }
    };

    obtenerIdDelBar();
  }, [nombreDelBar]);

  useEffect(() => {
    const obtenerIdDelBar = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const barQuerySnapshot = await getDocs(barQuery);

        if (!barQuerySnapshot.empty) {
          const barId = barQuerySnapshot.docs[0].id;
          setBarId(barId);

          // Escuchar cambios en el campo 'apagado' del documento del bar
          const barDocRef = doc(db, "bares", barId);
          const unsubscribe = onSnapshot(barDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const barData = docSnapshot.data();
              setApagado(barData.apagado);
            }
          });

          return unsubscribe;
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener el ID del bar:", error);
      }
    };

    obtenerIdDelBar();
  }, [nombreDelBar]);

  useEffect(() => {
    const obtenerPinBar = async () => {
      try {
        const barQuerySnapshot = await getDocs(
          query(collection(db, "bares"), where("nombre", "==", nombreDelBar))
        );
        if (!barQuerySnapshot.empty) {
          const barDoc = barQuerySnapshot.docs[0];
          const barData = barDoc.data();
          const barPin = barData.pin;
          setBarPin(barPin);
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener el pin del bar:", error);
      }
    };

    obtenerPinBar();
  }, [nombreDelBar]);

  useEffect(() => {
    Swal.fire({
      title: '¡Bienvenido a MozoCall!',
      icon: 'success',
      confirmButtonColor: "#da0707",
      confirmButtonText: 'Aceptar',
    });
  }, []);

  const limpiarMesa = async (id) => {
    try {
      const mesaRef = doc(db, "bares", barId, "mesas", id);
      await updateDoc(mesaRef, {
        estado: "Disponible",
        mozo: false,
        cuenta: false,
        msg: [],
      });
    } catch (error) {
      console.error("Error al limpiar la mesa:", error);
    }
  };

  const limpiarEstadoMozo = async (mesaId) => {
    try {
      const mesaRef = doc(db, "bares", barId, "mesas", mesaId);
      await updateDoc(mesaRef, {
        mozo: false,
      });
    } catch (error) {
      console.error("Error al limpiar el estado del mozo:", error);
    }
  };

  const limpiarEstadoCuenta = async (mesaId) => {
    try {
      const mesaRef = doc(db, "bares", barId, "mesas", mesaId);
      await updateDoc(mesaRef, {
        cuenta: false,
      });
    } catch (error) {
      console.error("Error al limpiar el estado de la cuenta:", error);
    }
  };

  const limpiarMensaje = async (mesaId) => {
    try {
      const mesaRef = doc(db, "bares", barId, "mesas", mesaId);
      await updateDoc(mesaRef, {
        msg: [],
      });
    } catch (error) {
      console.error("Error al limpiar el estado de la cuenta:", error);
    }
  };

  const openModal = (message, mesaId) => {
    setSelectedMessages(message);
    setShowModal(true);
    limpiarMensaje(mesaId);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMessages([]);
  };

  useEffect(() => {
    const adminLogged = localStorage.getItem("adminLogged");
    const barLogged = localStorage.getItem("barLogged");

    if (!adminLogged && !barLogged) {
      navigate(`/${nombreDelBar}/login`);
    }
  }, [nombreDelBar, navigate]);

  const cerrarSesion = () => {
    navigate(`/${nombreDelBar}/login`);
  };

  const toggleApagado = async () => {
    if (barId) {
      const barDocRef = doc(db, "bares", barId);
      try {
        await updateDoc(barDocRef, {
          apagado: !apagado,
        });
      } catch (error) {
        console.error("Error al actualizar el estado de apagado:", error);
      }
    }
  };

  const handleToggleApagado = () => {
    const action = apagado ? "prender" : "apagar";
    Swal.fire({
      title: `¿Estás seguro de que quieres ${action} el sistema?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Sí, ${action}`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleApagado();
        Swal.fire({
          title: "¡Confirmado!",
          text: `El sistema ha sido ${action === "apagar" ? "apagado" : "prendido"}.`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        });
      }
    });
  };

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
      >
        <div>
          <img
            className={MesaDetailStyles.imagencargando}
            src={imagen}
            alt=""
          />
        </div>
        <div className="text-center">
          <h4 className={pedido.tituloloader}>Cargando...</h4>
          <div className={`${pedido.loader} text-center`}></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`container ${pedido.containerPrincipal}`}>
        <div className={pedido.containercerrar}>
          <div className={pedido.containerApagar}>
            <button
              onClick={handleToggleApagado}
              className={pedido.cerrarSesion}
            >
              {apagado ? "Prender Sistema" : "Apagar Sistema"}{" "}
              <i className="bi bi-power"></i>
            </button>
            
          </div>
          <button
          className={pedido.cerrarSesion}
          onClick={() => setSoundEnabled(!soundEnabled)}
        >
          {soundEnabled ? "Desactivar Sonido" : "Activar Sonido"}
        </button>
          <button onClick={cerrarSesion} className={pedido.cerrarSesion}>
            Cerrar Sesión
          </button>
        </div>
        <div className={pedido.containerimagen}>
          <img className={pedido.imagen} src={imagen} alt="" />
          <div>
            <p className={pedido.mozolink}>MozoCall</p>
          </div>
        </div>

        <h1 className={pedido.titulo}>Pedidos</h1>
        <div className={pedido.mesasDisponibles}>
          <p className={`lead ${pedido.containerpin}`}>
            <span>Pin del día:</span>
            <span className={pedido.pin}>{barPin}</span>
          </p>

          <p className={pedido.mesasDisponiblesCount}>
            Mesas disponibles:{" "}
            <span className={pedido.mesaDisponible}> {mesasDisponibles}</span>{" "}
            de <span>{mesas.length}</span>
          </p>
        </div>
        <div
          className={`container ${pedido.containerPrincipal2} ${
            apagado ? pedido.fadeOut : ""
          }`}
        >
          {mesas.length > 0 ? (
            mesas
            .sort((a, b) => {
              if (a.estado === "Disponible" && b.estado !== "Disponible") return 1;
              if (a.estado !== "Disponible" && b.estado === "Disponible") return -1;
              return a.numero - b.numero;
            })
              .map((mesa) => (
                <div
                  className={`${pedido.estadoMesa} ${
                    mesa.mozo ? pedido.mozoSolicitado : ""
                  } ${mesa.cuenta ? pedido.cuentaSolicitada : ""} ${
                    mesa.msg.length > 0 ? pedido.mensajeSolicitado : ""
                  }`}
                  key={mesa.id}
                >
                  <h3 className={pedido.subtitulo}>Mesa {mesa.numero}:</h3>
                  {mesa.mozo && (
                    <i
                      className={`bi bi-bell-fill ${pedido.campanita} ${pedido.active}`}
                    ></i>
                  )}
                  <div className={pedido.containerEstado}>
                    <p
                      className={
                        mesa.estado === "Disponible"
                          ? pedido.estadoDisponible
                          : pedido.estadoNoDisponible
                      }
                    >
                      Estado: {mesa.estado}
                    </p>
                    <div className={pedido.seccion}>
                      <p className={mesa.cuenta ? pedido.solicitado : ""}>
                        Cuenta: {mesa.cuenta ? "Solicitada" : "No solicitada"}
                        {mesa.cuenta && (
                          <button
                            type="button"
                            className={` ${pedido.botonok} ${
                              apagado ? pedido.disableButtons : ""
                            }`}
                            onClick={() => limpiarEstadoCuenta(mesa.id)}
                          >
                            {/* ok */}
                            {/* <i class="bi bi-check2-circle"></i> */}
                            <i class="bi bi-hand-thumbs-up"></i>
                          </button>
                        )}
                      </p>
                    </div>
                    <div className={pedido.seccion}>
                      <div className={mesa.mozo ? pedido.solicitado : ""}>
                        Mozo: {mesa.mozo ? "Solicitado" : "No solicitado"}
                        {mesa.mozo && (
                          <button
                            type="button"
                            className={` ${pedido.botonok}`}
                            onClick={() => limpiarEstadoMozo(mesa.id)}
                          >
                            <i class="bi bi-check2"></i>
                          </button>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`btn ${pedido.solicitado} ${
                        mesa.msg.length > 0 ? pedido.mensajeRecibido : ""
                      }`}
                      onClick={() => openModal(mesa.msg, mesa.id)}
                    >
                      <i
                        className={`bi bi-envelope-fill ${pedido.icono} ${
                          mesa.msg.length === 0 ? pedido.sobreVacio : ""
                        }`}
                      ></i>
                      {mesa.msg.length > 0 && (
                        <span
                          className={`badge bg-danger ${pedido.badge} ${
                            mesa.msg.length > 0 ? pedido.mensajeNuevo : ""
                          }`}
                        >
                          <span className={pedido.numeroMensajes}>
                            {mesa.msg.length}
                          </span>
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      )}
                    </button>{" "}
                  </div>
                  <div className={pedido.containerBoton}>
                    <button
                      className={`btn btn-danger ${pedido.botonLimpiar}`}
                      onClick={() => limpiarMesa(mesa.id)}
                    >
                      Limpiar mesa
                    </button>
                  </div>
                </div>
              ))
          ) : (
            <p>No hay mesas disponibles</p>
          )}
        </div>
      </div>
      <Footer />
      <audio
        ref={audioRef}
        src={alertaSonido}
        onLoadedData={() => console.log("Audio cargado correctamente")}
      />
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className={`modal-title ${pedido.titulomodal}`}>
                  Mensajes
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                {selectedMessages.length > 0 ? (
                  selectedMessages.map(
                    (message, index) =>
                      message.trim() !== "" && (
                        <div key={index} className={pedido.mensajeModal}>
                          {message}
                        </div>
                      )
                  )
                ) : (
                  <div>No hay mensajes de esta mesa.</div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-secondary ${pedido.botonmodal}`}
                  onClick={closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Caja;
