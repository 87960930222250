import AboutUsStyles from "../styles/AboutUs.module.css";
import { useParams, useNavigate } from "react-router-dom";
import useTitle from "../hooks/useTitle.js";
import React, { useState, useEffect } from "react";
import { db } from "../firebase/Firebase.js";
import imagen from "../imagenes/necesito2.webp";


import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const AboutUs = () => {
  const navigate = useNavigate();
  const { nombreDelBar, numeroDeMesa } = useParams();
  const [barId, setBarId] = useState("");

  useTitle(`${nombreDelBar}: Menú`);

  const handleGoBack = () => {
    navigate(`/${nombreDelBar}/mesa/${numeroDeMesa}`);
  };
  useEffect(() => {
    const fetchBarId = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const unsubscribe = onSnapshot(barQuery, (snapshot) => {
          snapshot.forEach((doc) => {
            setBarId(doc.id);
          });
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener el ID del bar:", error);
      }
    };

    fetchBarId();
  }, [nombreDelBar]);

  return (
    <div className={`container ${AboutUsStyles.aboutUsContainer}`}>
      <div>
        <button className={AboutUsStyles.botonatras} onClick={handleGoBack}>
          {" "}
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
      </div>
      <div className={AboutUsStyles.containerImagen}>
        <img className={AboutUsStyles.imagen} src={imagen} alt="" />
        <div>
          <p className={AboutUsStyles.mozolink}>MozoCall</p>
        </div>
      </div>
      <div className="text-center">
        <h1 className={AboutUsStyles.title}>Sobre Nosotros</h1>
        <p className={AboutUsStyles.subtitle}>
          Mejora la experiencia en restaurantes y bares con MozoCall
        </p>
      </div>
      <div className={AboutUsStyles.content}>
        <section className={AboutUsStyles.section}>
          <h2 className={AboutUsStyles.sectionTitle}>Nuestra Misión</h2>
          <p className={AboutUsStyles.parrafos}>
            En MozoCall, nuestra misión es revolucionar la forma en que los
            restaurantes y bares gestionan sus menús y mesas. Nos esforzamos por
            proporcionar una herramienta eficiente y fácil de usar que permita a
            los negocios de bares y restaurantes optimizar su servicio y mejorar la
            experiencia del cliente.
          </p>
        </section>
        <section className={AboutUsStyles.section}>
          <h2 className={AboutUsStyles.sectionTitle}>Nuestra Visión</h2>
          <p className={AboutUsStyles.parrafos}>
            Visualizamos un mundo donde la tecnología juega un papel clave en la
            creación de experiencias gastronómicas memorables. Aspiramos a ser
            líderes en la digitalización de la gestión de restaurantes,
            facilitando un servicio más rápido y eficiente que beneficie tanto a
            los negocios como a los clientes.
          </p>
        </section>
        <section className={AboutUsStyles.section}>
          <h2 className={AboutUsStyles.sectionTitle}>Nuestro Equipo</h2>
          <p className={AboutUsStyles.parrafos}>
            MozoCall está desarrollado por un equipo apasionado de profesionales
            de la tecnología. Combinamos nuestra experiencia y
            conocimientos para crear una aplicación que realmente atienda las
            necesidades del sector. Nuestro compromiso es innovar continuamente
            para ofrecer las mejores soluciones.
          </p>
        </section>
        <section className={AboutUsStyles.section}>
          <h2 className={AboutUsStyles.sectionTitle}>Contacto</h2>
          <p className={AboutUsStyles.parrafos}>
            ¿Tienes alguna pregunta o sugerencia? Nos encantaría saber de ti.
            Puedes contactarnos a través de nuestro correo electrónico
            mozocall.soporte@gmail.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
