import React, { useState } from "react";
import { db } from "../firebase/Firebase";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import Estilos from "../styles/LoginBar.module.css";
import imagen from "../imagenes/necesito2.webp";

const LoginBar = () => {
  const { nombreDelBar } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const barQuery = query(
        collection(db, "bares"),
        where("nombre", "==", nombreDelBar)
      );
      const barDocs = await getDocs(barQuery);
      if (barDocs.empty) {
        setError("No se encontró un bar con ese nombre");
        return;
      }

      const barData = barDocs.docs[0].data();

      if (barData.password === password) {
        localStorage.setItem("barLogged", nombreDelBar);
        navigate(`/${nombreDelBar}`);
      } else {
        setError("Contraseña incorrecta");
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setError("Error al iniciar sesión, por favor inténtalo de nuevo");
    }
  };

  return (
    <div className={Estilos.bodyContainer}>
      <div className={Estilos.containerimagen}>
        <img className={Estilos.imagen} src={imagen} alt="" />
        <div>
          <p className={Estilos.mozolink}>MozoCall</p>
        </div>
      </div>

      <div className={Estilos.card}>
        <h2 className={Estilos.titulo}> {nombreDelBar}</h2>
        {error && <p className={Estilos.error}>{error}</p>}
        <form className={Estilos.form} onSubmit={handleLogin}>
          <input
            className={Estilos.input}
            type="text"
            placeholder="Nombre de usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className={Estilos.input}
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className={Estilos.boton} type="submit">
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginBar;
