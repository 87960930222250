import React, { useState, useEffect } from "react";
import estilos from "../styles/Codigos.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/Firebase.js";
import QRCode from "react-qr-code";
import MenuStyles from "../styles/Menu.module.css";
import imagen from "../imagenes/necesito2.webp";
import MesaDetailStyles from "../styles/MesaDetail.module.css";



const CodigosQR = () => {
  const { nombreDelBar } = useParams();
  const [mesas, setMesas] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const obtenerMesasDelBar = async () => {
      try {
        const barQuery = query(
          collection(db, "bares"),
          where("nombre", "==", nombreDelBar)
        );
        const barQuerySnapshot = await getDocs(barQuery);

        if (!barQuerySnapshot.empty) {
          const barId = barQuerySnapshot.docs[0].id;

          const mesasQuery = query(collection(db, "bares", barId, "mesas"));
          const mesasQuerySnapshot = await getDocs(mesasQuery);

          const mesasData = mesasQuerySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          mesasData.sort((a, b) => a.numero - b.numero);
          setMesas(mesasData);
          setLoading(false);
        } else {
          console.log("No se encontró ningún bar con el nombre especificado.");
        }
      } catch (error) {
        console.error("Error al obtener las mesas del bar:", error);
      }
    };

    obtenerMesasDelBar();
  }, [nombreDelBar]);

  const handleGoBack = () => {
    // navigate("/configuraciones"); 
    navigate(-1);
  };

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center vh-100 ${MesaDetailStyles.containercargando}`}
      >
        <div>
          <img
            className={MesaDetailStyles.imagencargando}
            src={imagen}
            alt=""
          />
        </div>
        <div className="text-center">
          <h4 className={MenuStyles.tituloloader}>Cargando...</h4>
          <div className={`${MenuStyles.loader} text-center`}></div>
        </div>
      </div>
    );
  }



  return (
    <div className="container">
      <div>
        <button className={estilos.botonatras} onClick={handleGoBack}>Volver</button>
      </div>

      <div className="text-center mb-3">
        <h2 className={`lead display-3 ${estilos.titulo}`}>{nombreDelBar}</h2>
      </div>
      <div>
        <h3 className={estilos.subtitulo}>Codigos QR para las mesas</h3>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {mesas.map((mesa, index) => (
          <div className="col" key={index}>
            <div className="card border-0">
              <div className={`card-body text-center ${estilos.cardd}`}>
                <img className={estilos.imagen} src={imagen} alt="" />
                <QRCode
                  value={`https://${document.domain}/${nombreDelBar}/mesa/${mesa.numero}`}
                />
                <h5 className="card-title mt-3">Mesa {mesa.numero}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CodigosQR;
